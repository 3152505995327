import { minWidthFromTheme } from '@sok/design-system'
import styled, { css } from 'styled-components'

interface ArticleGridContainerProps {
  backgroundColor?: string
  id?: string
}

export const StyledArticleGridContainer = styled.section<ArticleGridContainerProps>`
  padding: ${({ backgroundColor, theme }) => (backgroundColor ? `${theme.spacings.large} 0` : 0)};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-bottom: ${({ theme }) => theme.spacings.xxLarge};
  margin-top: ${({ theme }) => theme.spacings.xxLarge};
`

export const StyledVerticalImageAndTextContainer = styled.ul`
  display: grid;
  list-style: none;
  row-gap: ${({ theme }) => theme.spacings.large};

  ${({ theme }) => minWidthFromTheme(theme).tablet} {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    column-gap: ${({ theme }) => theme.spacings.small};
  }
`
export const StyledVerticalImageAndTextWrapper = styled.a`
  display: flex;
  text-decoration: none;
  color: inherit;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 ${({ theme }) => theme.spacings.small};
  ${({ theme }) => minWidthFromTheme(theme).desktop} {
    padding: 0;
    &:hover {
      box-shadow: 0 0 ${({ theme }) => theme.spacings.medium} rgba(0, 0, 0, 0.3);
    }
  }
`
export const StyledArticleGridTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacings.medium};
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: flex-start;
    margin-left: ${({ theme }) => theme.spacings.small};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-left: ${({ theme }) => theme.spacings.medium};
  }
`
export const StyledArticleHeading = styled.div`
  ${({ theme }) => css(theme.variants.body1.regular)};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacings.xSmall};
`

export const StyledArticleTitle = styled.h3`
  ${({ theme }) => css(theme.variants.body2.medium)};
  margin-bottom: ${({ theme }) => theme.spacings.xSmall};
`

export const StyledArticleContent = styled.div`
  ${({ theme }) => css(theme.variants.body1.regular)};
  display: flex;
  padding: ${({ theme }) => theme.spacings.medium};
  flex-direction: column;
  word-break: break;
  flex: 1;
`

export const StyledArticleCTALink = styled.div`
  text-decoration: none;
  padding: ${({ theme }) => theme.spacings.small};
  padding-left: ${({ theme }) => theme.spacings.medium};
  color: ${({ theme }) => theme.colors.primary500};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.sizes.xxs.fontSize};
  &:hover {
    color: ${({ theme }) => theme.colors.primary800};
  }
`

export const StyledCardImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 300px;
  object-fit: cover;
  object-position: 0 0;
`

export const StyledArticle = styled.article`
  display: flex;
  flex-direction: column;
  height: 100%;
`
