import { SKAUPAT_HREF_PRODUCTION } from 'constants/href'

/**
 * Checks if given link is not relative URL or under https://www.s-kaupat.fi.
 *
 * @example
 * isExternalLink('https://www.s-kanava.fi') // true
 * isExternalLink('https://www.s-kaupat.fi/tuotteet') // false
 * isExternalLink('/tuotteet') // false
 */
export const isExternalLink = (link: string) =>
  new URL(link, SKAUPAT_HREF_PRODUCTION).origin !== SKAUPAT_HREF_PRODUCTION

/**
 * Should always be used to validate link strings from external services
 * @example
 * getValidatedUrl('javascript:void(0)') // ''
 * getValidatedUrl('/tuotteet') // '/tuotteet'
 * getValidatedUrl('https://www.s-kaupat.fi/tuotteet') // '/tuotteet'
 * getValidatedUrl('https://www.foodie.fi/tuotteet') // 'https://www.foodie.fi/tuotteet'
 */
export const getValidatedUrl = (href?: string | null): string => {
  if (!href || href.startsWith('javascript')) return ''
  try {
    const url = new URL(href, SKAUPAT_HREF_PRODUCTION)
    if (url.protocol === 'http:' || url.protocol === 'https:') {
      const secureUrl = url.toString().replace('http:', 'https:')
      /**
       * Convert absolute `https://www.s-kaupat.fi` URL strings into relative.
       */
      return secureUrl.replace(SKAUPAT_HREF_PRODUCTION, '')
    }
    return ''
  } catch {
    return ''
  }
}
