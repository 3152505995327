import type { Asset } from 'contentful'

type ContentfulImageFormat = 'jpg' | 'png' | 'webp' | 'gif' | 'avif'

export const getOptimizedImageUrl = (
  image: Asset | undefined,
  width = 1680,
  format?: ContentfulImageFormat,
): string | undefined =>
  image?.fields?.file?.url
    ? `https:${image?.fields?.file?.url}?w=${width}&q=80${format ? `&fm=${format}` : ''}`
    : undefined
