import type { CitrusAdBanner } from '@shared/gql/document-nodes'

export enum CitrusAdPlacement {
  BANNERX_HOME = 'bannerx-homepage',
}

export enum CitrusAdSlot {
  HOME_HERO_CAROUSEL_2 = 'Homepage Banner Carousel',
  HOME_HERO_CAROUSEL_4 = 'Homepage Banner Carousel 1',
  HOME_MIDDLE = 'Homepage Banner Middle',
}

export interface CitrusAdBannerData {
  sessionId: string | null
  skipLoadAd: boolean
  loading: boolean
  placement: CitrusAdPlacement
  slotId: CitrusAdSlot
  banner?: Pick<
    CitrusAdBanner,
    | 'citrusAdId'
    | 'citrusCampaignName'
    | 'backgroundImage'
    | 'heroImage'
    | 'ctaLink'
    | 'ctaText'
    | 'headingText'
    | 'bannerText'
    | 'position'
    | 'gtins'
  >
}
