import type { Asset } from 'contentful'
import type { FC } from 'react'
import type { ICard } from 'types/contentful/generated'
import type { CustomImage } from 'utils/contentful/check-content-type'

import { TextBlock } from 'components/common/Titles'
import { getValidatedUrl } from 'domain/link-validation'
import Link from 'next/link'
import { useCallback } from 'react'
import { contentType } from 'utils/contentful/check-content-type'
import { internalPromotion } from 'utils/tracking/ecommerce'
import { useContentImpressionTracking } from 'utils/tracking/hooks/use-content-impression-tracking'
import { PromotionPosition } from 'utils/tracking/interfaces/data-layer-events'

import { ContentfulImage } from '../../ContentfulImage'
import { truncate } from '../common'
import {
  StyledArticle,
  StyledArticleContent,
  StyledArticleCTALink,
  StyledArticleHeading,
  StyledArticleTitle,
  StyledCardImage,
  StyledVerticalImageAndTextWrapper,
} from './styled'

/**
 * Add index number to the end of the prefix, and cast it because of `internalPromotion`'s types.
 * Index starts from 0, but is fixed to 1 internally.
 */
const getPromoPosition = (index: number, context?: 'menu-ajankohtaista') =>
  `${PromotionPosition.ARTICLE_GRID_PREFIX_}${index + 1}${
    context === 'menu-ajankohtaista' ? '_ajankohtaista-0' : ''
  }` as PromotionPosition.ARTICLE_GRID_PREFIX_

interface ArticleGridCardProps {
  index: number
  card: ICard
  context?: 'menu-ajankohtaista'
}

export const ArticleCardInnerContent: FC<{
  image?: CustomImage | Asset
  title?: string
  body?: string
  cta?: { label?: string; url?: string; newTab?: boolean }
  contentTag?: string
  handleClick?: () => void
}> = ({ image, handleClick, cta, title, body, contentTag }) => {
  return (
    <Link href={getValidatedUrl(cta?.url)} passHref>
      <StyledVerticalImageAndTextWrapper
        onClick={handleClick}
        target={cta?.newTab ? '_blank' : undefined}
        data-test-id="contentful-article-card"
      >
        <StyledArticle>
          {image ? (
            <div>
              {contentType.isContentfulImage(image) ? (
                <ContentfulImage sourceSizes="(min-width: 768px) 50vw, 100vw" image={image} lazy />
              ) : (
                <StyledCardImage
                  src={image.url}
                  alt={image.alt || image.title}
                  title={image.title}
                />
              )}
            </div>
          ) : null}
          <StyledArticleContent>
            {contentTag ? <StyledArticleHeading>{contentTag}</StyledArticleHeading> : null}
            <StyledArticleTitle>{title}</StyledArticleTitle>
            <TextBlock>{truncate(body)}</TextBlock>
          </StyledArticleContent>
          {cta?.label && (
            <StyledArticleCTALink>
              <span>{cta?.label}</span>
            </StyledArticleCTALink>
          )}
        </StyledArticle>
      </StyledVerticalImageAndTextWrapper>
    </Link>
  )
}

export const ArticleGridCard: FC<ArticleGridCardProps> = ({ index, card, context }) => {
  const { articleCategoryName, body, functionality, image, name, title } = card.fields
  const cta = functionality?.[0]?.fields
  const promotionPosition = getPromoPosition(index, context)
  const trackImpression = useCallback(() => {
    internalPromotion(promotionPosition, name, 'view_promotion', image?.fields.title)
  }, [promotionPosition, name, image?.fields.title])
  const ref = useContentImpressionTracking<HTMLDivElement>(trackImpression, promotionPosition)

  const handleClickTracking = useCallback((): void => {
    internalPromotion(promotionPosition, name, 'select_promotion', image?.fields.title)
  }, [promotionPosition, image?.fields.title, name])

  return (
    <div ref={ref}>
      <ArticleCardInnerContent
        handleClick={handleClickTracking}
        cta={{
          label: cta?.label,
          url: cta?.url,
          newTab: cta?.openLinkInNewTab,
        }}
        contentTag={articleCategoryName}
        title={title}
        body={body}
        image={image}
      />
    </div>
  )
}
