import type { RefObject } from 'react'

import { useEffect, useRef, useState } from 'react'

interface Options extends IntersectionObserverInit {
  disabled?: boolean
  once?: boolean
}

/**
 * Returns `true` when referenced element is intersecting,
 * most typically meaning visible on the screen. The `once`
 * option can be specified to stop tracking after element
 * first becomes visible.
 *
 * @example
 * const isVisible = useIsIntersecting(myDivRef)
 *
 * <div ref={myDivRef}>
 *  {isVisible ? 'I am visible!' : 'I am hidden!'}
 * </div>
 */
export const useIsIntersecting = (ref?: RefObject<HTMLElement>, options?: Options): boolean => {
  const [isDone, setIsDone] = useState(false)
  const [isIntersecting, setIsIntersecting] = useState(false)
  const observer = useRef<IntersectionObserver>()

  useEffect(() => {
    if (!ref?.current || isDone || options?.disabled) return

    /**
     * Disconnect previous observer
     * because it's about to be overriden
     */
    observer.current?.disconnect()

    const newObserver = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting)
        if (entry.isIntersecting && options?.once) {
          setIsDone(true)
          observer.current?.disconnect()
        }
      },
      {
        root: options?.root,
        rootMargin: options?.rootMargin,
        threshold: options?.threshold,
      },
    )

    newObserver.observe(ref.current)

    observer.current = newObserver

    return () => newObserver.disconnect()
  }, [
    isDone,
    options?.disabled,
    options?.once,
    options?.root,
    options?.rootMargin,
    options?.threshold,
    ref,
  ])

  return isIntersecting
}
