import type { Asset, Entry } from 'contentful'
import type {
  IAbTestingBlock,
  IContentBlock,
  IHeroCards,
  IHeroCardsCarousel,
  ISection,
} from 'types/contentful/generated'

type UnknownEntry = Entry<unknown>

export interface CustomImage {
  url?: string
  alt?: string
  title?: string
}

const isPageSection = (section: UnknownEntry): section is ISection =>
  section.sys.contentType.sys.id === 'section'

const isContentBlock = (section: UnknownEntry): section is IContentBlock =>
  section.sys.contentType.sys.id === 'contentBlock'

const isAbTestingBlock = (section: UnknownEntry): section is IAbTestingBlock =>
  section.sys.contentType.sys.id === 'abTestingBlock'

const isHeroCards = (section: UnknownEntry): section is IHeroCards =>
  section.sys.contentType.sys.id === 'heroCards'

const isHeroCardsCarousel = (section: UnknownEntry): section is IHeroCardsCarousel =>
  section.sys.contentType.sys.id === 'heroCardsCarousel'

const isContentfulImage = (image: CustomImage | Asset): image is Asset =>
  'sys' in image && image.sys.type === 'Asset'

export const contentType = {
  isPageSection,
  isContentBlock,
  isAbTestingBlock,
  isHeroCards,
  isHeroCardsCarousel,
  isContentfulImage,
}
