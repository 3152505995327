import type { Product } from 'domain/product'

import { ProductAvailabilityLabel } from '@shared/gql/document-nodes'

export const getProductAvailabilityForDate = (
  availabilities: Product['availabilities'],
  date?: string | null,
) => {
  /** Availability data is not loaded or hasn't been calculated. Assume good availability. */
  if (!availabilities || !date) return null

  const outOfStock = { date, label: ProductAvailabilityLabel.OutOfStock, score: null }

  /** When `availabities: []`, the product has not been sold at all and is completely out of stock */
  if (availabilities.length === 0) {
    return outOfStock
  }

  return availabilities?.find((a) => a.date === date) || outOfStock
}
