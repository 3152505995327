import type { FC, ReactNode } from 'react'

import { minWidthFromTheme } from '@sok/design-system'
import styled, { css } from 'styled-components'
import { TEXT_CONTENT_MAX_WIDTH } from 'styles/layout'

export const StyledSectionWrapper = styled.div<SectionWrapperProps>`
  display: flex;
  padding: 0 ${({ theme }) => theme.spacings.medium};
  margin: ${({ theme }) => `${theme.spacings.xLarge} 0`};
  ${({ theme }) => minWidthFromTheme(theme).desktop} {
    padding: 0 ${({ theme }) => theme.spacings.small};
  }
`

const StyledTextContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: flex-start;
  }
`

const StyledTextWrapper = styled.article`
  display: flex;
  flex-direction: column;
  width: ${TEXT_CONTENT_MAX_WIDTH}px;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`

export const StyledTextTitle = styled.h2`
  ${({ theme }) => css(theme.variants.title1)};
  margin-bottom: ${({ theme }) => theme.spacings.small};
`

export const StyledCardImageWrapper = styled.div`
  ${({ theme }) => minWidthFromTheme(theme).tablet} {
    width: 40%;
  }
`

export const StyledContentTextBlock = styled.div<ContentTextBlockProps>`
  ${({ theme }) => css(theme.variants.body2.regular)};
  word-break: break-word;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.action};
    &:hover {
      color: ${({ theme }) => theme.color.action};
    }
  }

  b {
    ${({ theme }) => css(theme.variants.body2.medium)};
  }

  p {
    margin-bottom: 16px;
    b {
      ${({ theme }) => css(theme.variants.body2.medium)};
    }
  }
  li {
    p {
      margin: 20px 0;
    }

    &:last-child {
      margin-bottom: 36px;
    }
  }

  ol,
  ul {
    margin-bottom: 16px;
    padding-left: 16px;
  }

  p:nth-last-child(1),
  ol:nth-last-child(1) {
    margin-bottom: 0px;
  }

  h1 {
    text-align: center;
    ${({ theme }) => css(theme.variants.title2)};
    margin-bottom: ${({ theme }) => theme.spacings.small};
  }
  h2 {
    ${({ theme }) => css(theme.variants.title1)};
    margin-bottom: ${({ theme }) => theme.spacings.small};
  }
`

interface TitleAndParagraphProps {
  title?: string
  body: ReactNode
}

interface SectionWrapperProps {
  id?: string
}

interface ContentTextBlockProps {
  marginBottom?: string
}

export const TitleAndParagraphText: FC<TitleAndParagraphProps> = ({ title, body }) => {
  return (
    <StyledTextContainer>
      <StyledTextWrapper>
        {title && <StyledTextTitle>{title}</StyledTextTitle>}
        {body && <StyledContentTextBlock>{body}</StyledContentTextBlock>}
      </StyledTextWrapper>
    </StyledTextContainer>
  )
}

export const truncate = (input?: string, length = 200): string | undefined =>
  input && input.length > length ? `${input.substring(0, length)}...` : input
