import type {
  CheckoutSteps,
  GAActionField,
  GAListProduct,
  GAProduct,
  GAProductAvailabilityLabel,
  GAPurchase,
  PromotionPosition,
  TrackingContext,
} from './interfaces/data-layer-events'
import type { I18nLang as Lang } from '@shared/domain/i18n'
import type {
  CartItem,
  HierarchyPathItem,
  Maybe,
  Order,
  PaymentMethod,
  ProductAvailabilities,
} from '@shared/gql/document-nodes'
import type { Product } from 'domain/product'

import { getConfig } from '@shared/config'
import { getProductAvailabilityForDate } from 'services/Product/utils/get-product-availability-for-date'

import { trackCustomEvent } from './custom-events/custom-events'
import { EventAction, EventCategory, OrderState } from './interfaces/data-layer-events'
import { dataLayer } from './tag-manager'

const { featureFlags } = getConfig()

/** [VOIK-7910] TODO: Do not report unknown availabilities when feature is disabled */
const DEFAULT_AVAILABILITY_LABEL = featureFlags.productAvailabilities ? 'UNKNOWN' : undefined

const getProductDifference = <T extends Pick<CartItem, 'id'>>(source: T[], comparison: T[]): T[] =>
  source.filter(
    (newCartItem) => !comparison.some((oldCartItem) => newCartItem.id === oldCartItem.id),
  )

/**
 * This function will return a modified version of cartItems,
 * where only the changes to a previous cart are included.
 * Note that negative changes are represented with a cartItem where itemCount is negative
 */
const mapCartDifferences = <T extends Pick<CartItem, 'id' | 'itemCount'>>(
  newCartItems: T[],
  oldCartItems: T[],
): T[] => {
  const addedCartItems = getProductDifference(newCartItems, oldCartItems)

  // Conver item count to negative for removed products
  const removedCartItems = getProductDifference(oldCartItems, newCartItems).map((oldCartItem) => {
    const itemCount = `-${oldCartItem.itemCount}`
    return { ...oldCartItem, itemCount }
  })

  const changedAmountItems = newCartItems
    .filter((newCartItem) => oldCartItems.some((oldCartItem) => newCartItem.id === oldCartItem.id))
    .filter((newCartItem) => {
      const oldCount = oldCartItems.find(
        (oldCartItem) => oldCartItem.id === newCartItem.id,
      )?.itemCount
      return newCartItem.itemCount !== oldCount
    })
    .map((cartItem) => {
      const oldCount =
        oldCartItems.find((oldCartItem) => oldCartItem.id === cartItem.id)?.itemCount || 0
      // have to calculate with numbers and then transfer to string to satisfy linter and types
      const itemCount = `${parseInt(cartItem.itemCount || '0', 10) - parseInt(oldCount || '0', 10)}`
      return { ...cartItem, itemCount }
    })

  return [...removedCartItems, ...addedCartItems, ...changedAmountItems]
}

type TransactionCartItem = Pick<
  CartItem,
  'itemCount' | 'basicQuantityUnit' | 'price' | 'name' | 'id'
> & {
  availabilities: ProductAvailabilities[] | null
}

const cartToGAProducts = (
  cartItems: TransactionCartItem[],
  availabilityDate: string | null,
): GAListProduct[] => {
  return cartItems.map((cartItem) => {
    // Handle special case of weighted items so that GA doesnt receive decimal quantities
    const count = cartItem.itemCount || '0'

    const price =
      cartItem.basicQuantityUnit === 'KG' ? cartItem.price * parseInt(count, 10) : cartItem.price

    const quantity = cartItem.basicQuantityUnit === 'KG' ? 1 : parseInt(count, 10)

    const currentAvailability = getProductAvailabilityForDate(
      cartItem.availabilities,
      availabilityDate,
    )

    return {
      availabilityLabel: currentAvailability?.label ?? DEFAULT_AVAILABILITY_LABEL,
      id: cartItem.id || '',
      name: cartItem.name || '',
      price: `${price}`,
      quantity,
    }
  })
}

const createGATransactionId = (order: { orderNumber: number | null }): string =>
  `${order.orderNumber}`

const getTotal = (products: GAListProduct[]): number =>
  products.map((cartItem) => Number(cartItem.price) * cartItem.quantity).reduce((a, b) => a + b, 0)

type TransactionOrder = Pick<
  Order,
  'discountCode' | 'storeId' | 'deliveryDate' | 'deliveryMethod' | 'deliveryTime' | 'orderNumber'
> & {
  cartItems: TransactionCartItem[] | null
}

/**
 * Creates a GA formatted data structure from client's orderData
 * and pushes to dataLayer via react-gtm-module
 */
export const trackTransactionCreate = ({
  availabilityDate,
  order,
  paymentMethod,
  storeName,
}: {
  availabilityDate: string | null
  order: TransactionOrder
  paymentMethod: PaymentMethod | 'SAVED_CARD_PAYMENT' | null
  storeName: string | undefined
}): void => {
  const products = cartToGAProducts(order.cartItems || [], availabilityDate)
  const purchase: GAPurchase = {
    actionField: {
      id: createGATransactionId(order),
      revenue: Number(getTotal(products).toFixed(2)),
      coupon: order.discountCode || undefined,
    },
    products,
  }

  dataLayer({
    event: 'purchase',
    ecommerce: {
      purchase,
    },
    customDimensions: {
      storeId: order.storeId,
      storeName,
      deliveryDate: order.deliveryDate,
      deliveryTime: order.deliveryTime,
      deliveryMethod: order.deliveryMethod,
      paymentMethod,
      orderState: OrderState.NEW,
      coupon: order.discountCode || undefined,
      // redundant field: savedPaymentMethod, but wanted by analytics
      // this is already expressed in paymentMethod by setting it to 'SAVED_CARD_PAYMENT'
      savedPaymentMethod: paymentMethod === 'SAVED_CARD_PAYMENT',
    },
  })

  trackCustomEvent({
    category: EventCategory.ECOMMERCE,
    action: EventAction.ORDER_TRANSACTION,
    label: createGATransactionId(order),
    value: purchase.actionField.revenue,
  })
}

export const trackTransactionEdit = ({
  availabilityDate,
  newOrder,
  oldOrder,
  paymentMethod,
  storeName,
}: {
  availabilityDate: string | null
  newOrder: TransactionOrder
  oldOrder: TransactionOrder
  paymentMethod: PaymentMethod | 'SAVED_CARD_PAYMENT' | null
  storeName: string | undefined
}): void => {
  const order = {
    ...newOrder,
    cartItems: mapCartDifferences(newOrder.cartItems || [], oldOrder.cartItems || []),
  }

  const products = cartToGAProducts(order.cartItems || [], availabilityDate)
  const purchase: GAPurchase = {
    actionField: {
      id: createGATransactionId(order),
      revenue: Number(getTotal(products).toFixed(2)),
      coupon: order.discountCode || undefined,
    },
    products,
  }

  dataLayer({
    event: 'purchase',
    ecommerce: {
      purchase,
    },
    customDimensions: {
      storeId: order.storeId,
      storeName,
      deliveryDate: order.deliveryDate,
      deliveryTime: order.deliveryTime,
      deliveryMethod: order.deliveryMethod,
      paymentMethod,
      orderState: OrderState.MODIFICATION,
      coupon: order.discountCode || undefined,
      // redundant field: savedPaymentMethod, but wanted by analytics
      // this is already expressed in paymentMethod by setting it to 'SAVED_CARD_PAYMENT'
      savedPaymentMethod: paymentMethod === 'SAVED_CARD_PAYMENT',
    },
  })

  trackCustomEvent({
    category: EventCategory.ECOMMERCE,
    action: EventAction.ORDER_MODIFICATION,
    label: createGATransactionId(order),
    value: purchase.actionField.revenue,
  })
}

export const cancelTransaction = (order: Pick<Order, 'orderNumber'>): void => {
  const actionField: GAActionField = {
    id: createGATransactionId(order),
  }

  dataLayer({
    event: 'cancelOrder',
    ecommerce: {
      refund: {
        actionField,
      },
    },
  })

  trackCustomEvent({
    category: EventCategory.ECOMMERCE,
    action: EventAction.ORDER_CANCEL,
    label: createGATransactionId(order),
  })
}

export const trackCartChange = ({
  availabilityLabel,
  id,
  listName,
  listPosition,
  metadata,
  name,
  productListContext,
  quantity = 1,
  type,
}: {
  availabilityLabel?: GAProductAvailabilityLabel
  id: string
  listName?: string | undefined
  listPosition?: number | undefined
  metadata?: Record<string, unknown>
  name: Maybe<string>
  productListContext: TrackingContext
  quantity?: number
  type: 'add' | 'remove'
}): void => {
  dataLayer({
    event: type,
    metadata,
    customDimensions: {
      productListContext,
      productListName: listName,
    },
    ecommerce: {
      [type]: {
        products: [
          {
            availabilityLabel: availabilityLabel ?? DEFAULT_AVAILABILITY_LABEL,
            id,
            list: listName,
            name,
            position: listPosition,
            quantity,
          },
        ],
      },
    },
  })
}

export const trackCheckout = (step: CheckoutSteps): void => {
  dataLayer({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step,
        },
      },
    },
  })
}

// @fixme [VOIK-3157] combine multiple promotions under one datalayer push.
export const internalPromotion = (
  position: PromotionPosition,
  name: string | undefined,
  type:
    | 'view_promotion'
    | 'select_promotion'
    | 'view_promotion_ca'
    | 'view_promotion_ca_iab'
    | 'select_promotion_ca',
  creative?: string,
  label?: string,
  id?: string,
): void => {
  dataLayer({
    event: type,
    ecommerce: {
      creative_name: creative,
      creative_slot: position,
      promotion_id: id ?? name,
      promotion_name: name,
      promotion_label: label ?? null,
      items: [null],
    },
  })
}

export const trackProductClick = (
  { availabilityLabel, category, id, name, price }: GAProduct,
  list: string,
  listPosition: number,
  metadata?: Record<string, unknown>,
): void => {
  dataLayer({
    event: 'productClick',
    metadata,
    ecommerce: {
      click: {
        actionField: { list },
        products: [
          {
            availabilityLabel: availabilityLabel ?? DEFAULT_AVAILABILITY_LABEL,
            category,
            id,
            listPosition,
            name,
            price,
          },
        ],
      },
    },
  })
}

type ProductDetails = Pick<
  Product,
  'name' | 'isGlobalFallback' | 'ean' | 'price' | 'isAgeLimitedByAlcohol'
> & {
  hierarchyPath: Maybe<Pick<HierarchyPathItem, 'slug'>[]>
}

type ProductAnalyticsAvailability =
  /** Product is available in the currently selected store */
  | 'AVAILABLE'
  /** Product is avaible, but not for home delivery. For example alcohol. */
  | 'NOT_AVAILABLE_FOR_DELIVERY'
  /** Product is available, but not for pickup. Unused? */
  | 'NOT_AVAILABLE_FOR_PICKUP'
  /** Product is found (globally), but not available in the current store */
  | 'NOT_AVAILABLE'
  /** Product is found, but there's no store selected so local availability is unknown */
  | 'STORE_NOT_SELECTED'

const getAvailability = (
  product: ProductDetails,
  inCurrentStore?: boolean | null,
  selectedStoreId?: string,
): ProductAnalyticsAvailability => {
  if (!selectedStoreId) return 'STORE_NOT_SELECTED'
  if (!inCurrentStore) return 'NOT_AVAILABLE'
  if (product.isAgeLimitedByAlcohol) return 'NOT_AVAILABLE_FOR_DELIVERY'
  return 'AVAILABLE'
}

export const trackProductDetailView = (
  product: ProductDetails,
  selectedStoreId?: string,
  localization?: null | Lang,
): void => {
  const { ean, hierarchyPath, isGlobalFallback, name, price } = product
  const inCurrentStore = !!selectedStoreId && !!product && !isGlobalFallback
  dataLayer({
    event: 'detail',
    ecommerce: {
      detail: {
        products: [
          {
            availability: getAvailability(product, inCurrentStore, selectedStoreId),
            category: hierarchyPath?.[0]?.slug,
            id: ean,
            language: localization,
            name,
            price,
          },
        ],
      },
    },
  })
}
