import type { Asset } from 'contentful'
import type { FC } from 'react'

import styled from 'styled-components'
import { getOptimizedImageUrl } from 'utils/common/image-utils'

interface ContentfulImageProps {
  image?: Asset
  sourceSizes?: string
  lazy?: boolean
  ['data-test-id']?: string
  className?: string
}

const SRCSET_IMG_WIDTH = [600, 800, 1000, 1200, 1400, 1680]

const OriginalImage: FC<{
  image: Asset
  className?: string
  lazy?: boolean
  dataTestId?: string
}> = ({ className, image, lazy, dataTestId }) => (
  <img
    className={className}
    src={getOptimizedImageUrl(image)}
    alt={image.fields.description ?? ''}
    title={image.fields.title ?? ''}
    loading={lazy ? 'lazy' : 'eager'}
    data-test-id={dataTestId}
  />
)

const _ContentfulImage: FC<ContentfulImageProps> = ({
  image,
  sourceSizes,
  'data-test-id': dataTestId,
  lazy,
  className,
}) => {
  if (!image) return null
  const isSvg = image.fields.file.contentType === 'image/svg+xml'

  if (isSvg)
    return <OriginalImage image={image} lazy={lazy} dataTestId={dataTestId} className={className} />

  let webpSrcSet = ''
  let srcSet = ''
  SRCSET_IMG_WIDTH.forEach((width) => {
    webpSrcSet += `${getOptimizedImageUrl(image, width, 'webp')} ${width}w, `
    srcSet += `${getOptimizedImageUrl(image, width)} ${width}w, `
  })
  return (
    <picture>
      <source srcSet={webpSrcSet} sizes={sourceSizes ?? '100vw'} type="image/webp" />
      <source srcSet={srcSet} sizes={sourceSizes ?? '100vw'} />
      <OriginalImage image={image} lazy={lazy} dataTestId={dataTestId} className={className} />
    </picture>
  )
}

export const ContentfulImage = styled(_ContentfulImage)({
  width: '100%',
})
