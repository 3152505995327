import { useCallback, useEffect, useRef, useState } from 'react'

import { useIsIntersecting } from '../../hooks/use-is-intersecting'

/**
 * Impression tracking for a component.
 * @example
 * // Regular type impression tracking
 * const ref = useContentImpressionTracking<HTMLDivElement>(callbackFn, 'tracking-id')
 * return (<div ref={ref}>...</div>)
 * // IAB type impression tracking
 * const ref = useContentImpressionTracking<HTMLDivElement>(callbackFn, 'tracking-id', true)
 * return (<div ref={ref}>...</div>)
 */
export const useContentImpressionTracking = <T extends HTMLElement>(
  callback: () => void,
  impressionTrackId: string,
  iabStandard?: boolean,
) => {
  const ref = useRef<T | null>(null)
  const [tracked, setTracked] = useState(false)
  const timeout = useRef<NodeJS.Timeout>()
  const isIntersecting = useIsIntersecting(ref, { once: false, threshold: iabStandard ? 0.5 : 0 })
  const handleImpression = useCallback(() => {
    if (isIntersecting) {
      callback()
      setTracked(true)
    }
  }, [callback, isIntersecting])

  useEffect(() => {
    if (isIntersecting && !tracked) {
      if (iabStandard) {
        timeout.current = setTimeout(handleImpression, 1000)
      } else {
        handleImpression()
      }
    }
    return () => {
      clearTimeout(timeout.current)
    }
  }, [handleImpression, iabStandard, isIntersecting, tracked, impressionTrackId])
  return ref
}
